<template>
  <div class="login">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">注册</div>
    <img class="bg" src="../../assets/img/loginbg.png" alt="">
    <div class="login-card">
        <div class="catalog">
            <p>用户名<span class="key">*</span></p>
            <input type="text" placeholder="6-20个字符" v-model="userName">
        </div>
        <div class="catalog">
            <p>密码<span class="key">*</span></p>
            <input type="password" placeholder="请输入密码" v-model="passwordState">
        </div>
        <div class="catalog">
            <p></p>
            <p class="remark">注：12-20个字符组成的密码，必须同时包含数字、小写字母、大写字母和特殊字符的三种及以上</p>
        </div>
        <div class="catalog">
            <p>确认密码<span class="key">*</span></p>
            <input type="password" placeholder="请再次输入密码" v-model="passwordAgain">
        </div>
        <div class="catalog">
            <p>企业名称<span class="key">*</span></p>
            <input type="text" placeholder="贵企业名称" v-model="enterName" maxlength="120">
        </div>
        <div class="catalog">
            <p>所属市<span class="key">*</span></p>
            <div class="select">
            <select v-model="cityCode">
                <option value="450100">南宁市</option>
                <option value="450200">柳州市</option>
                <option value="450300">桂林市</option>
                <option value="450400">梧州市</option>
                <option value="450500">北海市</option>
                <option value="450600">防城港市</option>
                <option value="450700">钦州市</option>
                <option value="450800">贵港市</option>
                <option value="450900">玉林市</option>
                <option value="451000">百色市</option>
                <option value="451100">贺州市</option>
                <option value="451200">河池市</option>
                <option value="451300">来宾市</option>
                <option value="451400">崇左市</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>区<span class="key">*</span></p>
            <div class="select">
            <select v-model="districtCode">
                <option v-for="item in district" :key="item.cityCode" :value="item.cityCode">{{item.cityName}}</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>企业性质<span class="key">*</span></p>
            <div class="select">
                <select v-model="enterProperty">
                    <option value="0">企业法人</option>
                    <option value="1">企业非法人</option>
                    <option value="2">事业单位</option>
                    <option value="3">个体户</option>
                    <option value="4">其他</option>
                </select>
            </div>           
        </div>
        <div class="catalog">
            <p>统一社会信用代码<span class="key">*</span></p>
            <input type="text" placeholder="务必真实准确填写" v-model="uniscid" maxlength="30">
        </div>
        <div class="catalog">
            <p>法人姓名<span class="key">*</span></p>
            <input type="text" v-model="legalName" placeholder="输入法人姓名" maxlength="50">
        </div>
        <div class="catalog">
            <p>法人身份证号<span class="key">*</span></p>
            <input type="text" v-model="legalCertiNum" placeholder="输入法人身份证号码" maxlength="30">
        </div>
        <div class="catalog">
            <p>联系人姓名<span class="key">*</span></p>
            <input type="text" v-model="contactsName" placeholder="输入联系人姓名" maxlength="30">
        </div>
         <div class="catalog">
            <p>手机号码<span class="key">*</span></p>
            <input type="number" placeholder="有效的11位手机号码" v-model="contactsPhone">
        </div>
        <div v-show="mobPhoneVeriCodeFlag === '1'" class="catalog">
            <p>手机验证码<span class="key">*</span></p>
            <input type="text" placeholder="验证码" style="width: 29vw;margin:0 1vw 0 3vw;" v-model="verificationCode">
            <div v-show="showVerifyBtn===0" class="verify" @click="sendVerify()">发送验证码</div>
            <div v-show="showVerifyBtn===1" class="verify2">
                <van-count-down
                ref="countDown"
                :time="60000"
                format="ss"
                @finish="countDownFinish"
                />秒
             </div>
        </div> 
        <div class="catalog">
            <p>电子邮箱</p>
            <input type="text" placeholder="联系人的电子邮箱" v-model="email" maxlength="50">
        </div>
        <div class="catalog" style="align-items: flex-start;">
            <p>引导机构</p>
            <div class="many">
            <div class="select">
            <select v-model="guideCity">
                <option value="">请选择</option>
                <option value="450100">南宁市</option>
                <option value="450200">柳州市</option>
                <option value="450300">桂林市</option>
                <option value="450400">梧州市</option>
                <option value="450500">北海市</option>
                <option value="450600">防城港市</option>
                <option value="450700">钦州市</option>
                <option value="450800">贵港市</option>
                <option value="450900">玉林市</option>
                <option value="451000">百色市</option>
                <option value="451100">贺州市</option>
                <option value="451200">河池市</option>
                <option value="451300">来宾市</option>
                <option value="451400">崇左市</option>
            </select>
            </div>
            <div class="select">
            <select v-model="guideBranch">
                <option value="">请选择</option>
                <option v-for="item in guideBranchList" :key="item" :value="item.orgCode">{{item.orgName}}</option>
            </select>
            </div>
            <div class="select">
            <select v-model="guideAgency">
                <option value="">请选择</option>
                <option v-for="item in guideAgencyList" :key="item" :value="item.orgCode">{{item.orgName}}</option>
            </select>
            </div>
            </div>
        </div>
         <div class="catalog">
            <p>引导人</p>
            <input type="text" v-model="guidePerson" maxlength="50">
        </div>
        <div class="agree">
        <van-checkbox v-model="agreeService" shape="square" icon-size="3.5vw">&nbsp;</van-checkbox>
        <p>我已阅读并同意<span class="blue" @click="openService">《广西征信融资服务平台用户注册服务协议》</span></p>      
        
        </div>
       

        <button class="login-btn" @click="submit()">注册</button>
        <div class="login-footer flex-between">
            <router-link to="/login" replace class="blue">马上登录</router-link>
            <router-link to="/passwordForget" replace class="gray">忘记密码</router-link>
        </div>

    </div>
    <!-- 服务协议 -->
    <van-popup round v-model:show="showService">
        <div class="pop">
            <div class="pop-header">广西征信融资服务平台用户注册服务协议</div>
            <div class="pop-content">
                本版发布日期：2021年11月1日<br/>
                本版生效日期：2021年11月1日<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;《广西征信融资服务平台用户注册服务协议》（以下简称“本协议”）是广西征信融资服务平台（以下简称“平台”或“本平台”）用户与平台运营机构就平台服务相关事宜达成的协议。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">• 提示条款<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;为了维护您的合法权益，请在签署本协议前，仔细阅读、充分理解本协议各条款内容，如您对本协议有任何疑问，可通过平台客服咨询。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，且受本协议全部条款约束；当您注册成功，无论是否进入平台，还是在平台发布任何内容，或者是直接或通过各类方式（如站外API引用等）间接使用平台的产品服务的行为，都将被视作为对本协议的认可。您如有违反本协议而导致的任何法律后果，由您自己独立承担相应的法律责任。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;在本协议履行过程中，本平台有权根据实际情况对本协议的相关条款进行修订并公告。如果您不同意本平台对本协议所做的修订，应立即停止使用平台服务。如果您继续使用平台服务，则视为接受本平台对本协议所做的修订，并应遵照修订后的协议履行应尽义务。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;一、协议的组成及签署<br/></span>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第一条</span> 本协议内容包括协议正文及所有平台已经发布的或将来通过平台官网可能发布或修改的各类规则（以下简称“规则”）。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。除平台另行明确声明外，任何与平台有关的一切服务、交易及非交易活动均受本协议约束。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第二条</span> 本平台有权在必要时修改服务条款，服务条款一旦发生变动，将会在首页上提示修改内容或通过其他形式告知用户。如果用户不同意所改动的内容，应当立即停止使用本平台相关服务。如果用户继续使用本平台服务，则视为接受服务条款的变动。平台发布的通知、公告、声明或其他类似的内容是本协议的一部分。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第三条</span> 用户应当在注册或使用平台服务前认真阅读并理解协议全部内容。如用户对协议有任何疑问的，应向平台运营机构咨询。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">二、用户账号</span><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第四条</span> 用户应按照注册要求如实填写信息。信息及相关资料发生变更时，用户有义务及时在本平台更新有关注册资料，以确保诸如身份信息、电子邮件地址、联系电话、联系地址、邮政编码等信息资料的真实、准确、完整。通过任何手段进行恶意注册或注册信息有误的，可能无法使用本平台服务，或所使用的服务范围可能会受到限制。用户有义务保证密码和账号的安全，用户利用该密码和账号所进行的一切活动引起的后果，由用户自行承担。如用户发现账号遭到未授权的使用或发生其他任何安全问题，应立即修改账号密码并妥善保管，如有必要，应通知本平台对相关账号采取保护措施。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">三、注册要求</span><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第五条</span> 申请注册成为广西征信融资服务平台用户，使用本服务，必须是依法成立并合法存续的企业法人或其他组织，或具有完全民事权利能力和完全民事行为能力的自然人，并应确保具备签署本协议、使用平台服务所需的各项授权、证照、批准和资质，能够独立承担法律责任。用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在本平台任何页面发布、转载、传送含有下列内容之一的信息，否则本平台有权自行处理（包括禁止发布、查看信息、限制用户权限、封停用户等）并不通知用户：<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（1）违反宪法确定的基本原则的；<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（2）危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的； <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（3）损害国家荣誉和利益的；<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（4）煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（5）破坏国家宗教政策，宣扬邪教和封建迷信的；<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（6）散布谣言，扰乱社会秩序，破坏社会稳定的； <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（8）侮辱或者诽谤他人，侵害他人合法权益的； <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（10）以非法民间组织名义活动的； <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（11）含有法律、行政法规禁止的其他内容的。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第六条</span> 当用户发表于本平台的信息侵犯其他用户或平台权利时，本平台有权删除涉嫌侵权信息。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第七条</span> 如用户在使用网络服务时违反下述任何规定，本平台有权要求用户改正或直接采取一切必要的措施以减轻用户不当行为造成的影响。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（1）不得为任何非法目的而使用本平台系统；<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（2）遵守所有与网络服务有关的网络协议、规定和程序；<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（3）不得利用本平台进行任何可能对互联网的正常运转造成不利影响的行为； <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（4）不得利用本平台进行任何不利于本平台的行为。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">四、隐私保护</span><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第八条</span> 用户在本平台注册应根据要求提供相关信息；在用户使用本平台服务、参加网站活动、访问网站网页时，网站自动接收并记录用户浏览器上的服务器数据，包括但不限于IP地址、网站Cookie中的资料及用户要求取用的网页记录；本平台承诺不公开或透露用户的隐私信息包括但不限于密码、手机号码等在本平台的非公开信息。但下列情况除外：<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（1）事先获得用户的明确授权或应允；<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（2）根据有关法律法规要求； <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（3）根据相关政府主管部门或司法机关按法定程序要求；<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（4）为维护社会公众的利益； <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（5）披露前已为公众所知悉； <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（6）为维护本平台的合法权益。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第九条</span> 用户使用本平台提供的第三方服务时，视同同意本平台按用户与第三方的约定，向其提供用户的注册资料等信息。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第十条</span> 本平台在向用户提供相关服务时，有权根据用户相关信息向用户推荐或展示相关政策、产品和服务信息。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">五、责任说明</span><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第十一条</span> 本平台无法保证网络服务一定能满足用户的要求，也不保证网络服务的及时性、安全性、准确性。对于因不可抗力或本平台不能控制的原因造成的网络服务中断或其他缺陷，本平台不承担任何责任。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第十二条</span> 本平台对第三方机构的服务仅提供展示，用户在使用第三方机构服务时产生的相关权利与责任，按用户与第三方机构的约定执行，本平台不承担任何责任。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第十三条</span> 本平台上关于用户发布的任何信息均由用户自行提供， 因此而产生的责任均由提供者全部承担，与本平台无关。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第十四条</span> 本平台有权修改或终止平台提供的相关服务，对此无需承担相关责任。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第十五条</span> 用户通过本平台与第三方发生的行为、结果及法律责任，均由用户与第三方自行承担。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第十六条</span> 用户经由使用平台服务获取的任何资料，平台不保证该信息的准确、及时和完整，用户应自行核实判断并承担相应风险。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">六、附则</span><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第十七条</span> 本平台运营机构对本协议条款具有最终解释权。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第十八条</span> 本协议的订立、执行、解释和争议的解决均应适用中华人民共和国法律。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第十九条</span> 因本协议的履行或本平台的使用产生的争议，双方应协商解决，协商不成的，任何一方均应向本平台运营单位所在地仲裁委申请仲裁解决。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第二十条</span> 若本协议的任一条款被视为无效或不可执行，不影响本协议其余条款的有效性及可执行性。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">第二十一条</span> 若用户继续注册或登录本平台，即视为完全接受并同意本协议内容，在注册或登录之前请再次确认已知悉并完全理解本协议的全部内容；若用户不同意本协议或其中任何条款约定，请立即停止注册或登录。<br/>
               
            </div>
            <div class="pop-footer">
                <div class="back" @click="closePop">确认</div>
            </div>
        </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast,Dialog } from 'vant';
import { reactive,ref,toRefs,toRaw,watch } from'vue';
import { useRouter } from 'vue-router';
import {useStore} from 'vuex';
import { apiGoRegister,apiSendVerificationCode,apiLogin,apiSaveRegisterInfo,apiGetDistrict,apiBranchBanks,apiSubBranchBanks } from '../../api/axios';
import { strEncVue } from '../../hooks/encrypt';
export default {
   setup() {
       let router = useRouter();
       let store = useStore();
       const countDown = ref(null);
       const state = reactive({
           passwordState:'',
           passwordAgain: '',
           showVerifyBtn: 0,
           mobPhoneVeriCodeFlag: '1',
           district:[{cityCode: '450101',cityName: '市辖区'}],
           guideCity: '',
           guideBranch: '',
           guideBranchList: [],
           guideAgencyList: []
       });
       const registerInfo = reactive({
           userName:'',
           password:'',
           enterName: '',
           cityCode:'450100',
           districtCode: '450101',
           enterProperty: '0',
           uniscid: '',
           legalName: '',
           legalCertiNum: '',
           contactsName: '',
           contactsPhone: '',
           verificationCode: '',
           email: '',
           guideAgency: '',
           guidePerson: '',
       });
       const agreeService = ref(false);
       const showService = ref(false);
        //进入注册页面
       apiGoRegister().then(res => {
            // 获取数据成功后的其他操作
           if(res.code === '1'){
                state.mobPhoneVeriCodeFlag = res.info.mobPhoneVeriCodeFlag;
                if(res.info.mobPhoneVeriCodeFlag==='0') {
                    registerInfo.verificationCode = '0';
                }    
            }
            else {
                Toast(res.msg);
            }           
        }).catch(err=>{
            console.log(err,'请求错误')
        });
        //获取区县
        function getDistrict() {
            apiGetDistrict({cityCode: registerInfo.cityCode}).then(res => {
                // 获取数据成功后的其他操作
            if(res.code === '1'){
                    state.district = res.info.districtList;
                    registerInfo.districtCode = state.district[0].cityCode;      
                }
                else {
                    Toast(res.msg);
                }           
            }).catch(err=>{
                console.log(err,'请求错误')
            }) 
        };
        getDistrict();
        //监听所属市变化
        watch(()=> [registerInfo.cityCode,state.guideCity,state.guideBranch], ([new1,new2,new3],[old1,old2,old3] ) => {
            if(new1 != old1) {
                getDistrict();
            }
            if(new2!=old2) {
                if(new2 != '') {                    
                    apiBranchBanks({cityCode: new2}).then(res => {                
                        if(res.code === '1'){
                            state.guideBranchList = res.info.bankList;
                            state.guideAgencyList.splice(0,state.guideAgencyList.length);
                            registerInfo.guideAgency = '';
                            state.guideBranch = '';                            
                        }
                        else {
                            Toast(res.msg);
                        }           
                    })
                }else {
                    state.guideBranchList.splice(0,state.guideBranchList.length);
                    state.guideBranch = '';
                    state.guideAgencyList.splice(0,state.guideAgencyList.length);
                    registerInfo.guideAgency = '';
                }
                
            }
            if(new3 != old3) {
                if(new3 != '') {
                    apiSubBranchBanks({branchBankCode: new3}).then(res => {
                        if(res.code === '1'){
                            state.guideAgencyList = res.info.subBankList;
                            registerInfo.guideAgency = '';  
                        }
                        else {
                            Toast(res.msg);
                        }           
                    })
                }else {
                    state.guideAgencyList.splice(0,state.guideAgencyList.length);
                    registerInfo.guideAgency = '';  
                }
            }

        })

        //发送验证码
       const sendVerify = () => {
           let phoneNum = registerInfo.contactsPhone.toString();  
           if(phoneNum.length != 11) {
            //    console.log(phoneNum)
               Toast('手机号格式错误');
               return;
           }else {
                apiSendVerificationCode({contactsPhone: phoneNum}).then(res => {
                // 获取数据成功后的其他操作
                if(res.code === '0'){
                        Toast(res.msg);
                    }           
                }).catch(err=>{
                    console.log(err,'请求错误')
                }) 
                state.showVerifyBtn = 1;
                countDown.value.reset();//重置倒计时
           }

          
       };
       //倒计时完成
       const countDownFinish = () => {
           state.showVerifyBtn = 0;           
       };
       const openService= () => {
            showService.value = true;
        };
        const closePop = () =>{
            showService.value = false;
        }
       let testPassword =/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{12,20}$/;
       let registrationIdA = 'webtest01';
       window.uploadRegisterId = (str)=>{
        registrationIdA = str;
      };
        try {
            window.scanJsAgent.uploadRisId();
            } catch (e) {
            console.log('获取手机id失败');
        }
       //提交注册
       const submit = () =>{
           
           if(registerInfo.userName.length < 6 || registerInfo.userName.length > 20) {
               Toast('用户名需6-20个字符组成。')
               return;
           }
           if(!testPassword.test(state.passwordState)) {
               Toast('密码需12-20个字符组成, 且必须同时包含数字、小写字母、大写字母和特殊字符的三种及以上。')
               return;
           }
           if(state.passwordAgain != state.passwordState) {
               Toast('两次密码输入不一致。')
               return;
           }else {
               registerInfo.password = strEncVue(state.passwordState,registerInfo.verificationCode)
           }
           if(registerInfo.enterName.length < 1) {
               Toast('企业名称需由1-120个字符成。')
               return;
           }
           if(registerInfo.uniscid.length < 1) {
               Toast('请先填写正确格式的统一社会信用代码。')
               return;
           }
           if(registerInfo.legalName.length < 1) {
               Toast('请先填写正确格式的法人姓名。')
               return;
           }
           if(registerInfo.legalCertiNum.length < 1) {
               Toast('请先填写正确格式的法人身份证号。')
               return;
           }
           if(registerInfo.contactsName.length < 1) {
               Toast('请先填写正确格式的联系人姓名')
               return;
           }
           if(registerInfo.verificationCode.length < 1) {
               Toast('请先输入验证码。');
               return;
           }
           registerInfo.contactsPhone = registerInfo.contactsPhone.toString();
           if(registerInfo.contactsPhone.length !== 11) {
               Toast('请先填写正确格式的联系人手机号码')
               return;
           }
           if(!agreeService.value) {
               Toast('请先阅读并同意《广西征信融资服务平台用户注册服务协议》。')
               return;
           }
            // console.log(toRaw(registerInfo),992);
           apiSaveRegisterInfo(toRaw(registerInfo)).then(res => {
            if(res.code === '1'){
                Dialog.confirm({
                title: '注册成功',
                message:
                    '您已注册成功，点击【确认】进行企业认证。如果暂时不进行认证，则点击【取消】返回首页，后续再进行企业认证。',
                })
                .then(() => {
                    let loginObj = {
                        userName:registerInfo.userName,
                        password:state.passwordAgain,
                        visible: '1',
                        registrationId: registrationIdA
                    }
                    apiLogin(loginObj).then(res => {
                        if(res.code == '6') {
                            let storage = res.info;
                            storage.userName = registerInfo.userName; 
                            localStorage.setItem("gxrToken", JSON.stringify(storage));
                            store.dispatch('setToken',storage);
                            router.push({ name:'auth',replace:true}); 
                        }else {
                            Toast('出现错误，请登录后再进行企业认证');
                            router.push({ name:'login',replace:true}); 
                        }
                    });                    
                })
                .catch(() => {
                    router.push({ name:'home',replace:true}); 
                });
                    // Toast.success({
                    //     forbidClick: true,
                    //     message: '注册成功',
                    // });
                    // router.push({ name:'login',replace:true}); 
                }
                else {
                    Toast.fail(res.msg);
                }           
            }).catch(err=>{
                console.log(err,'请求错误')
            })
       }
       return {
           agreeService,
           showService,
           openService,
           closePop,
           countDown,
           ...toRefs(state),
           ...toRefs(registerInfo),
           sendVerify,
           countDownFinish,
           submit
           
       }
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.login {
    background-color: #fff;
    .navTitle {
        background: none;
    }
    img.bg {
        width: 100%;
        height: auto;
    }
    .login-card {
        margin: 0 8vw;
        padding-bottom: 15vw;
        .catalog {
            display: flex;
            align-items: center;
            
            p:first-child {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                .key {
                    color: #ff6600;
                }
            }
            p:last-child {
                width: 55vw;
                font-size: 4vw;
                padding-left: 4vw;

            }
            select,input {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 55vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
            }
            .many {
                width: 59vw;
                .select {
                    margin-bottom: 4vw;
                }
            }
            .verify {
                color: #ff9700;
                padding: 1vw 2vw;
                border: 1px solid #ff9700;
                border-radius: 1.5vw;
                font-size: 4vw;
            }
            .verify2 {
                padding: 1vw 0;
                border: 1px solid #ff9700;
                border-radius: 1.5vw;
                font-size: 4vw;
                // color: #999;
                color: #ff9700;
                width: 23vw;
                display: flex;
                justify-content: center;
            }

        }
        .agree {
            margin-top: 4vw;
            font-size: 3.5vw;
            color: #666;
            display: flex;
            align-items: baseline;
           
        }
        .login-btn {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4.5vw;
            padding: 3vw 6vw;
            border-radius: 7vw;
            border: 0;
            margin-top: 8vw;
            width: 100%;
            letter-spacing: 2vw;
        }   
    
    }
    .remark {
        font-size: 3.8vw !important;
        color:#999
    }
    .blue {
            color: #3399ff
        }
    .login-footer {
        margin-top: 4vw;        
        font-size: 4vw;
        
        .gray {
            color: #666666;
        }
    }
    .pop {
        padding: 4vw;
        width: 70vw;
        .pop-header {
            font-size: 5vw;
            font-weight: bold;
            text-align: center;
            margin-bottom: 4vw;
        }
        .pop-content {
            width: 100%;
            height: 60vh;
            font-size: 4vw;
            overflow-y: auto;
            line-height: 6vw;
            letter-spacing: 1px;
            .black-font {
                font-weight: bold;
            }
        }
        .pop-footer {
            text-align: center;padding-top: 4vw;
            .back {
                display: inline-block;
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4vw;
                padding: 1.5vw 0;
                border-radius: 5vw;
                width: 22vw;
            }
        }
    }
    
}
</style>
